@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Bold';
  box-sizing: border-box;
}
p, td, span {
  font-family: 'Regular';
}

h2 {
  font-family: 'Inika-Regular';
}

/* customer tabs styling */
li[aria-expanded='true'] > a {
  background-color: #ea5b1b !important;
}

.p-highlight {
  border-color:#ea5b1b !important;
}

.p-menuitem-link {
  background-color: #213b87 !important;
  margin-left: 8px !important;
}
.p-menuitem-link.selectedTab {
  background-color: #ea5b1b !important;
}
.p-menuitem-link:hover {
  background-color: #ea5b1b !important;
  transition: all 300ms ease !important;
}

.p-menuitem-text {
  color: white;
}
.p-menuitem-icon {
  color: white;
}

::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: white;        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #213b87;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid white;  /* creates padding around scroll thumb */
}

@media (max-width: 720px) {
  .p-menuitem-link {
    width: 100px;
    padding: 10px !important;
    justify-content: center;
  }
  .p-menuitem-icon {
    display: none !important;
  }
}

@media (max-width: 540px) {
  .p-menuitem-link {
    margin-left: 2px !important;
    width: 80px;
    font-size: small;
  }
}

@media (max-width: 420px) {
  .p-menuitem-link {
    width: 60px;
    font-size: x-small;
  }
}

/* Stopping input feild turning blue when selecting a suggested value */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* Custom Fonts */
/* CheyenneSans */
@font-face {
  font-family: "Black";   
  src: local("CheyenneSans-Black"),
    url("./fonts/CheyenneSans-Black.ttf") format("truetype");
}
@font-face {
  font-family: "BlackItalic";   
  src: local("CheyenneSans-BlackItalic"),
    url("./fonts/CheyenneSans-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Bold";   
  src: local("CheyenneSans-Bold"),
    url("./fonts/CheyenneSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "BoldItalic";   
  src: local("CheyenneSans-BoldItalic"),
    url("./fonts/CheyenneSans-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ExtraBold";   
  src: local("CheyenneSans-ExtraBold"),
    url("./fonts/CheyenneSans-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "ExtraBoldItalic";   
  src: local("CheyenneSans-ExtraBoldItalic"),
    url("./fonts/CheyenneSans-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "ExtraLight";   
  src: local("CheyenneSans-ExtraLight"),
    url("./fonts/CheyenneSans-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "ExtraLightItalic";   
  src: local("CheyenneSans-ExtraLightItalic"),
    url("./fonts/CheyenneSans-ExtraLightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Italic";   
  src: local("CheyenneSans-Italic"),
    url("./fonts/CheyenneSans-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Light";   
  src: local("CheyenneSans-Light"),
    url("./fonts/CheyenneSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: "LightItalic";   
  src: local("CheyenneSans-LightItalic"),
    url("./fonts/CheyenneSans-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Medium";   
  src: local("CheyenneSans-Medium"),
    url("./fonts/CheyenneSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "MediumItalic";   
  src: local("CheyenneSans-MediumItalic"),
    url("./fonts/CheyenneSans-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Regular";   
  src: local("CheyenneSans-Regular"),
    url("./fonts/CheyenneSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SemiBold";   
  src: local("CheyenneSans-SemiBold"),
    url("./fonts/CheyenneSans-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "SemiBoldItalic";   
  src: local("CheyenneSans-SemiBoldItalic"),
    url("./fonts/CheyenneSans-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Thin";   
  src: local("CheyenneSans-Thin"),
    url("./fonts/CheyenneSans-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "ThinItalic";   
  src: local("CheyenneSans-ThinItalic"),
    url("./fonts/CheyenneSans-ThinItalic.ttf") format("truetype");
}

/* Inika */

@font-face {
  font-family: "Inika-Bold";   
  src: local("Inika-Bold"),
    url("./fonts/Inika-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Inika-Regular";   
  src: local("Inika-Regular"),
    url("./fonts/Inika-Regular.ttf") format("truetype");
}

/* Hand writing */

@font-face {
  font-family: "signature";
  src: local("signature"),
    url("./fonts/Hurricane-Regular.ttf") format("truetype")
}



